.ant-popover-inner-content{
  padding: 0 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  border-color: black !important;
}
.ant-input-affix-wrapper-focused{
  box-shadow: none;
}
.ant-modal-header{
  border-bottom: 0 !important;
}
/*解决ant message里图标和字体不对齐的问题*/
.ant-message svg{
  vertical-align: baseline;
}
.ant-message .ant-message-notice-content{
  transform: translateX(76px);
}
.ant-tooltip-placement-right .ant-tooltip-arrow{
  width: 20px;
  left: -5.68976536px;

}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow{
  bottom: -6.8416px;
  left:10px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 10px;
}
.ant-select-selector:hover{
  border: 1px solid #282A37 !important;
}
.ant-select-selector-focused{
  border-color: #282A37 !important;
}
/* nft 下拉选项配置 */
.nft-select .ant-select-selector{
  height: 100% !important;
  align-items: center;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: transparent;
}
.nft-select .ant-select-arrow{
  font-size: 10px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: inherit;
  box-shadow: none;
}