@font-face {
  font-family: "inter";
  src: url('Inter-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: "inter-medium";
  src: url('Inter-Medium.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: "inter-blod";
  src: url('Inter-Bold.otf') format('opentype');
  font-weight: bold;
}