@import '~antd/dist/antd.css';
@import '../assets/fonts/Inter/inter.css';
@import './antd.custom.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    font-family: inter, system-ui, sans-serif;
    font-weight: 400;
  }
}